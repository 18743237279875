'use client';

import { trackWebVitals } from '@sbt-web/tracking';
import { useReportWebVitals } from 'next/web-vitals';
import { getOrCreatePulse } from '@tools/tracking/utils';

export default function WebVitals(): undefined {
  useReportWebVitals((metric) => {
    const pulseInstance = getOrCreatePulse();

    if (pulseInstance !== undefined) {
      trackWebVitals(pulseInstance, metric);
    }
  });
}
