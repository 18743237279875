type Constraints = {
  maxWidth?: number;
  maxHeight?: number;
  isLazyLoad?: boolean;
};

export const createSkyscraperSizeConstraints = (
  skyscraperLeftId: string,
  skyscraperRightId: string
): Record<string, Constraints> | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const mainComponent = document.querySelector('main');
  if (!mainComponent) {
    return undefined;
  }

  const windowWidth = window.innerWidth;
  const mainComponentWidth = mainComponent.clientWidth;
  const skyscraperMaxWidth = Math.floor((windowWidth - mainComponentWidth) / 2);

  if (skyscraperMaxWidth <= 0) {
    return undefined;
  }

  return {
    [skyscraperLeftId]: {
      maxWidth: skyscraperMaxWidth,
    },
    [skyscraperRightId]: {
      maxWidth: skyscraperMaxWidth,
    },
  };
};
