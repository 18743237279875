import { CategoryID, MacroCategoryID } from '@sbt-web/network/types';
import { getMacroOfCategoryID } from '@sbt-web/utils';

export enum Theme {
  Red = 'redCorporate',
  Blue = 'blueMotori',
  Purple = 'purpleImmobili',
  Green = 'greenLavoro',
  Yellow = 'yellowMarket',
}

const macroThemes: Record<MacroCategoryID, Theme> = {
  [CategoryID.Tutte]: Theme.Red,
  [CategoryID.Motori]: Theme.Blue,
  [CategoryID.Immobili]: Theme.Purple,
  [CategoryID.LavoroServizi]: Theme.Green,
  [CategoryID.Elettronica]: Theme.Yellow,
  [CategoryID.CasaPersona]: Theme.Yellow,
  [CategoryID.SportsHobby]: Theme.Yellow,
  [CategoryID.Altri]: Theme.Yellow,
};

export const getCategoryThemeById = (categoryId: CategoryID): Theme =>
  macroThemes[getMacroOfCategoryID(categoryId).id];
