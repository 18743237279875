import type { HebeModel, Vertical } from '@sbt-web/adv';

type ErrorType = 'notfound' | 'gone';
const createMsiteErrorConfig =
  (errorType: ErrorType, errorCode: string) =>
  (vertical: Vertical): HebeModel => {
    const name = `error${errorType}`;

    return {
      name,
      page_level_settings: {},
      slots: [
        {
          container_id: `${name}-belowthefold`,
          name: 'Below the fold',
          configurations: {
            gam: [
              {
                sequence: 0,
                unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-msite-${errorCode}-box`,
                sizes: [
                  '300x250',
                  '300x600',
                  'fluid',
                ] as `${number}x${number}`[],
                bidding_systems: [
                  {
                    bidders: [
                      {
                        name: 'criteo',
                        params: {
                          networkId: 280,
                        },
                      },
                      {
                        name: 'criteo',
                        params: {
                          networkId: 280,
                          zoneId: 1479794,
                        },
                      },
                      {
                        name: 'openx',
                        params: {
                          delDomain: 'adevintaitaly-d.openx.net',
                          unit: 541156884,
                        },
                      },
                      {
                        name: 'rubicon',
                        params: {
                          accountId: 23660,
                          siteId: 381482,
                          zoneId: 2114030,
                        },
                      },
                      {
                        name: 'pubmatic',
                        params: {
                          publisherId: '81997',
                          adSlot: 'Subito_300x250-hb@300x250',
                        },
                      },
                      {
                        name: 'pubmatic',
                        params: {
                          publisherId: '81997',
                          adSlot: 'Subito_300x600-hb@300x600',
                        },
                      },
                      {
                        name: 'teads',
                        params: {
                          pageId: 149522,
                          placementId: 163688,
                        },
                      },
                      {
                        name: 'appnexus',
                        params: {
                          placementId: 19698628,
                        },
                      },
                      {
                        name: 'ix',
                        params: {
                          siteId: 1131819,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      ],
    };
  };

export const msiteErrorNotFoundConfig = createMsiteErrorConfig(
  'notfound',
  '404'
);
export const msiteErrorGoneConfig = createMsiteErrorConfig('gone', '410');
