export const LiraContainerIds = {
  page410: {
    skyscraperLeft: 'errorgone-skyscraperleft',
    skyscraperRight: 'errorgone-skyscraperright',
    belowTheFold: 'errorgone-belowthefold',
  },
  pageError: {
    skyscraperLeft: 'errornotfound-skyscraperleft',
    skyscraperRight: 'errornotfound-skyscraperright',
    belowTheFold: 'errornotfound-belowthefold',
  },
  adView: {
    skyscraperLeft: 'adview-skyscraper-left',
    skyscraperRight: 'adview-skyscraper-right',
    //When we are full on Lira, we should use this id adview-skin instead of apn_skin_tag
    skin: 'apn_skin_tag',
    bottom: 'adview-bottom',
    top: 'adview-top',
    aside: 'adview-aside',
    aside2: 'adview-aside2',
    boxBelow1: 'adview-boxbelow1',
    boxBelow2: 'adview-boxbelow2',
    boxBelow3: 'adview-boxbelow3',
    // Lazy components:
    replyBox: 'adview-intothereplybox',
    belowGallery: 'adview-belowgallery',
    anchorGallery: 'adview-anchorgallery',
    sponsorGallery: 'adview-sponsorgallery',
    vehicleHistory: 'adview-vehicle-history',
    //TODO: please standardize the name of this container
    videoPreroll: 'video_preroll',
    imageIntoGalleryForPro: 'apn_gallery_adview',
    imageIntoGalleryForProMsite: 'apn_gallery_adview_msite',
    imageIntoGalleryForPrivate: 'apn_gallery_adv_adview',
    imageIntoGalleryForPrivateMsite: 'apn_gallery_adv_adview_msite',
  },
  listing: {
    skin: 'apn_skin_tag',
    top: 'listing-top',
    aside: 'listing-aside',
    skyscraperLeft: 'listing-skyscraper-left',
    skyscraperRight: 'listing-skyscraper-right',
    aboveListing: 'listing-above',
    interstitial: 'interstitial',
    interstitialLazy: 'interstitial-lazy',
    middle1: 'listing-middle-1',
    middle2: 'listing-middle-2',
    middle3: 'listing-middle-3',
    middle4: 'listing-middle-4',
    middle5: 'listing-middle-5',
    middle6: 'listing-middle-6',
    middle7: 'listing-middle-7',
    native1: 'listing-native-1',
    native2: 'listing-native-2',
    native3: 'listing-native-3',
    native4: 'listing-native-4',
    native5: 'listing-native-5',
    native6: 'listing-native-6',
    box1: 'listing-box-1',
    box2: 'listing-box-2',
    box3: 'listing-box-3',
    belowListing: 'listing-below',
    zeroResult: 'listing-zero-result',
  },
};
