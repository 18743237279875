import type { Category } from '@sbt-web/utils';
import type { ItemCategory } from '@sbt-web/network/types';

/*
 * Utility function that returns a category in the slug format
 * from listing category or ad category
 */
export const getCategoryForStickyBanner = (
  category?: Category | ItemCategory
): string | undefined => {
  if (!category) {
    return;
  }

  if ('friendlyName' in category) {
    return category.friendlyName;
  }

  if ('friendly' in category) {
    return category.friendly;
  }
};
