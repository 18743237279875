import ErrorPage from '@client/components/Scaffolding/Errors';
import FourTen, {
  type Props as FourTenProps,
} from '@client/components/Scaffolding/Errors/410';
import Layout from '@client/components/Scaffolding/Layout';
import { UserProvider } from '@client/contexts/UserContext';
import {
  markPerformance,
  Marks,
} from '@client/utilities/performance/performanceMonitor';
import { didomiScriptElements } from '@sbt-web/didomi';
import type { ItemCategory } from '@sbt-web/network/types';
import {
  ClientHintsContextData,
  ClientHintsProvider,
  useViewport,
  ViewportProvider,
} from '@sbt-web/hooks';
import {
  createOptimizelyInstance,
  OptimizelySubitoLogHandler,
  OptimizelySubitoProvider,
  setOptimizelyLogger,
} from '@sbt-web/houston-wrapper';
import { HTTPStatusCode } from '@sbt-web/network/types';
import { Category, isBrowser } from '@sbt-web/utils';
import { captureException } from '@sentry/nextjs';
import getClientHints from '@shared/helpers/ClientHints';
import { DeviceType, getDeviceType } from '@shared/helpers/DeviceDetector';
import { getOrCreatePulse } from '@tools/tracking/utils';
import { Response } from 'express';
import App, { AppContext, AppInitialProps } from 'next/app';
import Head from 'next/head';
import React from 'react';

/**
 * === READ ME ===
 * Next.js now supports local CSS imports,
 * so you can import the component CSS directly
 * in the file where it's used.
 *
 * Only add CSS here if it's truly global (e.g. used in both pages, always).
 *
 * https://nextjs.org/docs/basic-features/built-in-css-support#import-styles-from-node_modules
 */
import '@sbt-web/ui/dist/index.css';
import '@sbt-web/ancillary-services/dist/index.css';
import '@sbt-web/breadcrumbs/dist/index.css';
import '@sbt-web/share-button/dist/index.css';

//Carousel style:
import '@sbt-web/carousel/style';
import { generateMetadata } from '@sbt-web/adv';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
import { Theme } from '@shared/helpers/Themes';
import WebVitals from '@client/components/WebVitals';

/** ^^^ READ ABOVE THE IMPORTS ^^^ */

export type GonePageProps = {
  statusCode: HTTPStatusCode.Gone;
} & FourTenProps;

export type BasePageProps = {
  statusCode: HTTPStatusCode;
  theme: Theme;
  pageName: string;
  deviceType?: DeviceType | null;
  clientHints?: ClientHintsContextData;
  category?: Category | ItemCategory;
} & (
  | GonePageProps
  | { statusCode: Exclude<HTTPStatusCode, HTTPStatusCode.Gone> }
);

markPerformance(Marks.GLOBAL_RUN_APP);

setOptimizelyLogger(OptimizelySubitoLogHandler);
const optimizely = createOptimizelyInstance(
  process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT
);

export default class extends App<AppInitialProps<BasePageProps>> {
  static async getInitialProps(
    appContext: AppContext
  ): Promise<AppInitialProps> {
    const pageProps = {
      ...(await App.getInitialProps(appContext)).pageProps,
      deviceType: getDeviceType(appContext.ctx.req),
      clientHints: getClientHints(appContext.ctx.req),
    };

    return {
      pageProps,
    };
  }

  componentDidCatch(error: Error | null) {
    captureException(error, {
      level: 'error',
      tags: { topLevelCatch: true },
    });
  }

  render() {
    const { Component, pageProps } = this.props;

    const isServer = !isBrowser();

    return (
      <>
        <Head>
          {didomiScriptElements({
            env: process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT,
          })}
          {generateMetadata()}
        </Head>
        <WebVitals />
        <ClientHintsProvider {...pageProps.clientHints}>
          <ViewportProvider defaultType={pageProps.deviceType ?? 'mobile'}>
            {pageProps.statusCode === HTTPStatusCode.OK && (
              <TopTags pageName={pageProps.pageName} />
            )}
            <OptimizelySubitoProvider
              optimizely={optimizely}
              environmentId={getOrCreatePulse()?.getEnvironmentId()}
              isServerSide={isServer}
            >
              <UserProvider>
                <Layout
                  theme={pageProps.theme}
                  pageName={pageProps.pageName}
                  category={pageProps.category}
                >
                  {pageProps.statusCode !== HTTPStatusCode.OK ? (
                    pageProps.statusCode === HTTPStatusCode.Gone ? (
                      <FourTen
                        categoryId={pageProps.categoryId}
                        categoryLabel={pageProps.categoryLabel}
                        categoryDefaultUrl={pageProps.categoryDefaultUrl}
                        items={pageProps.items}
                      />
                    ) : (
                      <ErrorPage statusCode={pageProps.statusCode} />
                    )
                  ) : (
                    <Component {...pageProps} />
                  )}
                </Layout>
              </UserProvider>
            </OptimizelySubitoProvider>
          </ViewportProvider>
        </ClientHintsProvider>
      </>
    );
  }
}

export function setStatusOnServer<T extends HTTPStatusCode>(
  statusCode: T,
  res: Response | undefined
): { statusCode: T } {
  if (res) {
    res.status(statusCode);
  }

  return { statusCode };
}

function TopTags({ pageName }: { readonly pageName: string }) {
  const { isMobile, isDesktop } = useViewport();

  return (
    <>
      <style>
        {`
            #skinadvtop2 ~ body #${LiraContainerIds.adView.skin} {
              height: 0!important;
            }
        `}
      </style>
      {isMobile ? null : (
        <div
          id={LiraContainerIds.adView.skin}
          style={{
            maxHeight: '122px',
            position: 'sticky',
            textAlign: 'center',
            top: 0,
            height: pageName === 'listing' ? 90 : 0,
          }}
        />
      )}
      {isDesktop ? (
        <div
          id="apnx_preroll_wrapper"
          style={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <div
            id="apnx_preroll"
            style={{
              display: 'none',
              width: '400px',
              marginRight: '20px',
              marginBottom: '73px',
              textAlign: 'end',
              backgroundColor: '#fff',
            }}
          />
          <div
            id="apnx_preroll_close_button"
            style={{
              position: 'absolute',
              top: '4px',
              right: '25px',
              width: '35px',
              height: '20px',
              zIndex: 2,
              display: 'none',
              fontSize: '12px',
              fontWeight: 500,
              cursor: 'pointer',
            }}
          >
            Chiudi
          </div>
        </div>
      ) : null}
    </>
  );
}
